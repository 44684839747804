.Container {
  /* display: flex;
  flex-flow: column nowrap;
  align-items: center; */
  max-height: 82vh;
  overflow: hidden;
}
.Header {
  /* border: 1px solid red; */
  text-align: center;
  color: var(--mainWhite);
  font-size: 2em;
  margin-bottom: 2vh;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div{
    flex: 1;
  }
}

@media screen and (max-width: 600px) {
  div.container {
    /* width: 90vw; */
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
  }
}
  