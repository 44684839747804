@media screen and (min-width: 701px) {
div.Container{
  /* align-self: center; */
  justify-self: right;
  flex-flow: column nowrap;
  height: 100%;
  /* max-height: calc(100vh - 6.3%); */
  /* max-height: 600px; */
  margin-top: 5px;
  /* flex: 1; */
  overflow-x: auto;
  /* white-space: nowrap; */
  /* border-radius: 0 var(--brdRadius) var(--brdRadius) 0; */
  background-color: var(--mainColor);

  div.TopDiv {
    text-align: center;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    max-width: 100px;
    padding: 20px;
    margin: 5px;
    overflow-x: hidden;
  }
}


/* .TopDiv button {
  background-color: transparent;
  color: var(--mainWhite);
  border: 0px;
} */
}

@media screen and (max-width: 700px) {
  div.Container{
    position: fixed;
    bottom: 0;
    width: 100vw;
    /* border-radius: 8px 8px 0 0; */
    background-color: var(--mainColor);
    display: flex;
    justify-content: center;
    /* overflow-x: auto;
    overflow-y: hidden; */
    height: 100px;
    border-radius:0;

    div.TopDiv {
      margin-top: 5px;
      display: flex;
      /* gap: 15px; */
      /* margin-left: 8px; */
      /* margin-right: 8px; */
      overflow-x: auto;
      overflow-y: hidden;
      scrollbar-height: 3px;
    }

    div.TopDiv > *{
      padding-left: 12px;
    }

    div.TopDiv:nth-child(1){
      padding-right: 12px;
    }

    div.TopDiv::-webkit-scrollbar{
      height: 3px;
    }
  }


}


@media (max-height: 300px) {
  div.Container{
    height: 0;
  }
}