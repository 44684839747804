div.container {
  align-self: center;
  height: 100%;
  width: 100%;
  z-index: 1;

  div.AppBody {
    height: 100%;
  }
}

@media screen and (max-width: 600px) {
    div.AppBody {
      width: 100vw;
      height: 100%;
      /* display: flex;
      flex-flow: column-reverse wrap; */
    }
  }
