/* @media screen and (min-width: 601px) { */
.Container{
    height: max-content;

    div.PaymentBtns{
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* width: 60%; */
        
        
        div{
            font-family: 'Heebo', sans-serif;
            font-size: 11px;
            height: 40px;
            font-weight: 400;
            border-radius: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin-top: 1vh;
            position: relative;
            white-space: normal;
            .CloseBtn{
                position: absolute;
                left: 0;
                color: var(--mainColor);
                font-size: 22px;
                font-weight: 700;
                width: max-content;
                /* margin-inline-end: 1px; */
            }
        }

        .couponBtn{
            white-space: pre-wrap;
            border: 1px solid var(--mainColor);
            color: var(--mainColor);
            position: relative;
            span{
                font-weight: 700;
                text-decoration: underline;
                background-color: unset;
            }
        }

        article {
            background-color: red;
            color: whitesmoke;
            white-space: normal;
            margin-top: 2px;
            margin-bottom: 0;
            width: 80%;
        }

        .goToPayment{
            padding-top: 2px;
            padding-bottom: 2px;
            font-size: 20px;
            font-weight: 400;
            color: #FFFFFF;
        }

        :nth-child(5){
            height: 20px;
            cursor: default;
        }
        /*
        div:nth-child(3):hover {
            cursor: default;
        } */
    }

     /* > * + *{
        margin-top: 1vh;
    } */
}


@media (min-resolution: 140dpi){
    /* div.Container{
        div.PaymentBtns{
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 55%;
            
            div{
                font-size: 10px;
                font-weight: 400;
                height: 35px;
                border-radius: 20px;
                padding-top: 5px;
                padding-bottom: 1px;
            }

            div:nth-child(2){
                font-size: 17px;
            }
            
            div:nth-child(2) {
                margin-top: 5vh;
            }
        } 
        
        div.PaymentBtns > * + *{
            margin-top: 1vh;
        }
    } */
}

@media (max-width: 650px){
    div.Container{
        div.PaymentBtns{
            div:nth-child(5) {
                margin-top: 5vh;
            }
        }
    }
}